/** @jsx jsx */
import { jsx, Flex, Box, Heading, Text } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

// import Bio from "../src/components/Bio"
import Section from "../components/Section"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

class BlogTemplate extends React.Component {
  render() {
    const blog = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    let anchor = this.props.location.pathname
      .replace(/\/blog\//g, "")
      .replace(/\//g, "")
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={blog.frontmatter.title}
          description={blog.frontmatter.description}
        />
        <Section>
          <Link to={"/blog#" + anchor}>
            ↰&nbsp;&nbsp;&nbsp;&nbsp;zurück zum Blog
          </Link>
          <article sx={{ width: "100%" }}>
            <header sx={{ mt: 4, mb: 3 }}>
              <Text>{blog.frontmatter.dateFormatted}</Text>
              <Heading
                as="h1"
                sx={{ fontSize: [8, 8, 8, 8, 1], lineHeight: 1 }}
              >
                {blog.frontmatter.title}
              </Heading>
              <Text>{blog.frontmatter.authorName}</Text>
            </header>
            <Img
              fluid={blog.frontmatter.image.childImageSharp.fluid}
              sx={{ mb: 3 }}
              imgStyle={{
                border: `${blog.frontmatter.border}px solid #999`,
              }}
            />
            <section>
              <MDXRenderer>{blog.body}</MDXRenderer>
            </section>
            <hr sx={{ mt: 4 }} />
            {blog.frontmatter.authorPic.childImageSharp.fixed && (
              <footer sx={{ display: "flex", alignItems: "center" }}>
                <Img
                  fixed={blog.frontmatter.authorPic.childImageSharp.fixed}
                  sx={{ mr: 3, flex: "0 0 auto" }}
                  imgStyle={{
                    borderRadius: "50%",
                    border: "1px solid #999",
                  }}
                />
                <Text>
                  {blog.frontmatter.authorName}
                  {blog.frontmatter.authorStatement}
                </Text>
              </footer>
            )}
          </article>
          <nav sx={{ width: "100%", mt: 3 }}>
            <Link to={"/blog#" + anchor}>
              ↰&nbsp;&nbsp;&nbsp;&nbsp;zurück zum Blog
            </Link>
            <Flex
              sx={{ justifyContent: "space-between", flexWrap: "wrap", mt: 2 }}
            >
              <Box>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </Box>
              <Box>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </Box>
            </Flex>
          </nav>
        </Section>
      </Layout>
    )
  }
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 500)
      body
      frontmatter {
        eventId
        title
        subtitle
        dateFormatted: date(formatString: "DD MMMM YYYY")
        description
        authorName
        authorStatement
        authorPic {
          childImageSharp {
            fixed(width: 60, height: 60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        border
      }
    }
  }
`
